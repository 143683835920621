export const resetErrorFields = form => {
    if (!form) return;
    const errorFields = form.querySelectorAll('.error');
    errorFields.forEach(field => {
        field.classList.remove('error');
        const textWrapper = field.querySelector('.js-text-wrapper');
        if (textWrapper) textWrapper.textContent = '';
    });
};

export const setErrorFields = (errors, form) => {
    errors.forEach(errorField => {
        const { name, text } = errorField;
        const errorElement = form.querySelector(`[name="${name}"]`);
        if (errorElement) {
            const topParent = errorElement.parentElement.parentElement;
            topParent.classList.add('error');

            if (text) {
                const textWrapper = topParent.querySelector('.js-text-wrapper');
                if (textWrapper) textWrapper.textContent = text;
            }
        }
    });
};
