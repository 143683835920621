import { resetErrorFields, setErrorFields } from '../modules/formFieldsErrors';

const forms = document.querySelectorAll('.js-form');


if (forms.length > 0) {
    const resetMessageOutput = messageOutput => {
        if (!messageOutput) return;
        messageOutput.classList.remove('success');
        messageOutput.classList.remove('error');
        messageOutput.textContent = '';
    };

    const setMessageOutput = (messageOutput, status, text) => {
        if (!messageOutput || !status || !text) return;

        messageOutput.classList.add(status);
        messageOutput.textContent = text;
    };

    const handleFormSubmit = async e => {
        const { target } = e;
        const data = new FormData(target);
        const messageOutput = target.querySelector('.form__output-wrapper');
        const loader = target.querySelector(".loader");

        const dataRedirectTo = target.getAttribute("data-redirect-to");

        const checkboxes = target.querySelectorAll("input[type='checkbox']");

        checkboxes.forEach(checkbox => {
            if (!checkbox.checked)
                data.append(checkbox.getAttribute('name'), 'null');
        });

        if (messageOutput) resetMessageOutput(messageOutput);
        resetErrorFields(target);

        try {
            if (loader) loader.classList.add("-active");
            const req = await fetch(ajax.url, {
                method: 'POST',
                body: data,
            });

            const { data: formData } = await req.json();

            const { status, message, errorFields } = formData;

            if (status !== 'success') {
                if (errorFields && errorFields.length > 0) {
                    setErrorFields(errorFields, target);
                }
            } else {
                target.reset();

                if (dataRedirectTo && dataRedirectTo.length > 10) location.href = dataRedirectTo;
            }

            setMessageOutput(messageOutput, status, message);
        } catch (error) {
            console.error(`Error: ${error}`);
        } finally {
            if (loader) loader.classList.remove("-active");
        }
    };

    forms.forEach(form => {
        form.addEventListener('submit', e => {
            e.preventDefault();
            handleFormSubmit(e);
        });
        
        form.addEventListener('keydown', e => {
            if (e.key === 'Enter') {
                const activeElement = document.activeElement;
                if (activeElement.getAttribute("type") === 'checkbox') {
                    e.preventDefault();
                    if (!activeElement.checked) {
                        activeElement.checked = true;
                    } else {
                        activeElement.checked = false;
                    }
                }
            }
        });
    });
}
